@import url("https://p.typekit.net/p.css?s=1&k=oug4uwf&ht=tk&f=39255.39256.39257.39258.39259.39260.39261.39262&a=6508642&app=typekit&e=css");

@font-face {
  font-family: "ff-scala-sans-pro";
  src: url("https://use.typekit.net/af/646cf6/0000000000000000774f9b92/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/646cf6/0000000000000000774f9b92/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3")
      format("woff"),
    url("https://use.typekit.net/af/646cf6/0000000000000000774f9b92/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
}

@font-face {
  font-family: "ff-scala-sans-pro";
  src: url("https://use.typekit.net/af/89cdda/0000000000000000775112bb/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/89cdda/0000000000000000775112bb/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
      format("woff"),
    url("https://use.typekit.net/af/89cdda/0000000000000000775112bb/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 900;
  font-stretch: normal;
}

@font-face {
  font-family: "ff-scala-sans-pro-bold";
  src: url("https://use.typekit.net/af/38e3b5/0000000000000000774f9b93/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/38e3b5/0000000000000000774f9b93/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/38e3b5/0000000000000000774f9b93/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: "ff-scala-sans-pro";
  src: url("https://use.typekit.net/af/3dd6be/0000000000000000774ff1ee/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/3dd6be/0000000000000000774ff1ee/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/3dd6be/0000000000000000774ff1ee/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: "ff-scala-sans-pro-italic";
  src: url("https://use.typekit.net/af/67153a/0000000000000000774ff1e3/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/67153a/0000000000000000774ff1e3/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/67153a/0000000000000000774ff1e3/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "ff-scala-sans-pro-light";
  src: url("https://use.typekit.net/af/b7041d/0000000000000000774f9bb0/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/b7041d/0000000000000000774f9bb0/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("woff"),
    url("https://use.typekit.net/af/b7041d/0000000000000000774f9bb0/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
}

@font-face {
  font-family: "ff-scala-sans-pro";
  src: url("https://use.typekit.net/af/5a9bd0/0000000000000000774ff1f2/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/5a9bd0/0000000000000000774ff1f2/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3")
      format("woff"),
    url("https://use.typekit.net/af/5a9bd0/0000000000000000774ff1f2/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 300;
  font-stretch: normal;
}

@font-face {
  font-family: "ff-scala-sans-pro-regular";
  src: url("https://use.typekit.net/af/395f1b/0000000000000000774ff1eb/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/395f1b/0000000000000000774ff1eb/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/395f1b/0000000000000000774ff1eb/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

.tk-ff-scala-sans-pro {
  font-family: "ff-scala-sans-pro", sans-serif;
}

@font-face {
  font-family: "proxima_novaregular";
  src: url("./Mark Simonson - Proxima Nova.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
